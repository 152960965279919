import router from './router'
import Store from './store'
import {getJsonDataFromLocal} from '@/utils/storageFunc';
router.beforeEach((to, from, next) => {
  if (to.path === '/login') { // login path
    next();
  } else if (to.path === '/forget-pw') {
    next();
  } else if (to.path === '/signup') {
    next();
  } else {
    if (Store.state.userData.user === null) {
      next('/login');

      // (async function () {
      //   const userData = Store.state.getters.getUser();
      //   if (userData != null) {
      //     Store.commit("setUser", userData);
      //     next({ path: to.fullPath });
      //   } else {
      //     next('/login');
      //   }
      // }())
    } else { // added 
      next();
    }
  }
});