import customAxios from '@/api/customAxios';
import Store from '@/store/index';
import AuthApi from '@/api/authApi';
import UserApi from '@/api/userApi';
import MemoApi from '@/api/memoApi'
import WeatherApi from '@/api/weatherApi';
import MigrationApi from '@/api/migrationApi';
import PersonalSettingApi from '@/api/personalSettingApi';
import TrashApi from '@/api/trashApi';
import CarApi from '@/api/carApi';
import ScheduleApi from '@/api/scheduleApi';
import ParkingApi from '@/api/parkingApi';
import CodebookApi from '@/api/codebookApi';
import BoardApi from '@/api/boardApi';
import BoardCommentApi from '@/api/boardCommentApi';
import InquiryApi from '@/api/inquiryApi';
import NotificationApi from '@/api/notificationApi';
import mapApi from '@/api/mapApi';
import placeApi from '@/api/placeApi';

export default {
  ...AuthApi,
  ...UserApi,
  ...WeatherApi,
  ...MemoApi,
  ...PersonalSettingApi,
  ...TrashApi,
  ...CarApi,
  ...ScheduleApi,
  ...ParkingApi,
  ...CodebookApi,
  ...BoardApi,
  ...BoardCommentApi,
  ...InquiryApi,
  ...NotificationApi,
  ...mapApi,
  ...placeApi,

  async getCryptoKey() {
    const url = '/common/crypto-key';
    const response = await customAxios({
      url, method: 'GET', headers: {'Authorization': 'Bearer ' + Store.state.userData.user.token}
    })
    return Promise.resolve(response.data);
  },
  ...MigrationApi,
}

/* 다음과 같이 요청함
try {
  const res = await networkController.getMemoList(this.page)
  if(res.code === 0) {
    const data = res.data
  }
} catch(err) { }
*/

