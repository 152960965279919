import axios from 'axios'
import mixin from '@/mixin';
import {clearVuex} from '@/store/clear';

const MAX_RETRY_COUNT = 2;

const customAxios = axios.create({
  // 기본 서버 주소 입력
  baseURL: process.env.NODE_ENV === 'production'
    ? 'https://purplediary.kr/api'
    : 'http://localhost:7777',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// handle response
const handleResponse = response => {
  if(process.env.NODE_ENV === 'development' && response.data) console.log(response.data)
  return response;
}
// handle error
const handleError = error => {
  console.log("%c오류 발생\n", "font-size:15px;color:orange;", error.response);
  if(error.response.status === 403) {
    clearVuex();
    mixin.methods.replaceRoute('/login', {})
    return;
  }
  const config = error.config;
  config.retryCount = config.retryCount !== undefined ? config.retryCount : 0;

  const shouldRetry = config.retryCount < MAX_RETRY_COUNT;

  if (shouldRetry && error.response.status !== 400 && error.response.status !== 404) {
    config.retryCount += 1;
    return customAxios(config);
  }

  return Promise.reject(error);
};

const { request, response } = customAxios.interceptors;
response.use(handleResponse, handleError);
export default customAxios